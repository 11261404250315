.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.floating-whatsapp {
  cursor: pointer;
  position: fixed;
  z-index: 99999;
  bottom: 3vh;
  text-decoration: none;
  font-size: 14px;
  right: 25px;
  font-weight: 800;
  width: 150px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #34A853;
  box-shadow: 0px 4px 20px 0px rgba(255, 255, 255, 0.25);
  color: #fff;
}

.floating-whatsapp-mobile {
  cursor: pointer;
  position: fixed;
  display: block;
  top: 90vh;
  z-index: 100000000000;
  text-decoration: none;
  font-size: 14px;
  right: 25px;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #34A853;
  box-shadow: 0px 4px 20px 0px rgba(255, 255, 255, 0.25);
  color: #fff;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.hero-container-home {
  background: #000;
  color: white;
  padding-top: 80px;
  padding-bottom: 70px;
}

.hero-container-home .hero-title {
  text-align: left;
}

.hero-container-home .hero-description {
  text-align: left;
  font-size: 1.2em;
}

.hero-container {
  background: #121212;
  color: white;
  padding-top: 80px;
}

.home-video-section {
  width: 100%;
  padding: 0 !important;
  object-fit: cover;
  max-height: 80vh;
}


.box-animate {
  background: #444;
  position: relative
}

.box-animate:after,
.box-animate:before {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #10a5b8;
  position: absolute;
  top: -80px;
  left: 15px;
  opacity: 0;
  z-index: 1;
  transition: all .35s ease
}

.box-animate:after {
  top: auto;
  left: auto;
  bottom: -80px;
  right: 15px
}

.box-animate:hover:after,
.box-animate:hover:before {
  opacity: .75;
  transform: scale(8);
  transition-delay: .15s
}

.box-animate img {
  width: 100%;
  height: auto;
  transition: all .35s ease-out 0s
}

.box-animate:hover img {
  opacity: .4
}

.box-animate .icon {
  margin: 0;
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 2;
  transform: scale(0);
  transition: all .35s ease-out
}

.box-animate:hover .icon {
  transform: scale(1);
  transition-delay: .15s
}

.box-animate .icon li a {
  display: block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  background: #fff;
  font-size: 18px;
  color: #444;
  margin-right: 10px;
  position: relative;
  transition: all .5s ease 0s
}

.box-animate .icon li a:hover {
  background: #444;
  color: #fff
}

.box-animate .box-content {
  padding: 20px 15px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1
}

.box-animate .title {
  font-size: 20px;
  font-weight: 800;
  color: #fff;
  margin: 0 0 5px;
  opacity: 0;
  transform: translate(-20px, -20px);
  transition: all .1s ease-out
}

.box-animate:hover .title {
  opacity: 1;
  transform: translate(0, 0);
  transition-delay: .1s
}

.box-animate .post {
  display: inline-block;
  font-size: 16px;
  color: #fff;
  opacity: 0;
  transform: translate(-20px, -20px);
  transition: all .35s ease-out
}

.box-animate:hover .post {
  opacity: 1;
  transform: translate(0, 0);
  transition-delay: .15s
}

.box-animate .icon,
.box-animate .icon li {
  display: inline-block
}

.hero-title h1 {
  font-size: 2em;
  font-weight: bold;
}

.hero-title-home h1 {
  font-size: 3em;
  font-weight: bold;
}

.section-title h2 {
  color: #fff;
  font-size: 2em;
  text-align: center;
  font-weight: 600;
}

.container-irs {
  background: #000;
  color: white;
  padding-bottom: 80px;
}

.container-icon {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  align-self: stretch;
  justify-content: center;
}

.subcon-icon {
  width: 2.7rem;
  height: 2.7rem;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(241, 149, 9, 0.2);
  color: rgb(241, 149, 9);
  border-radius: 0.5rem;
  text-align: center;
}

.subcon-icon .icon {
  font-size: 0.87rem;
  font-weight: 400;
  color: #F19509;
}

/* ==== Contact ====*/
.section-title-contact h2 {
  color: #fff;
  font-size: 1.25rem;
  text-align: left;
  font-weight: 600;
}

.button-width {
  width: 100%;
}

/* Portfolio */
.container-event {
  padding: 3rem;
}

@media screen and (max-device-width: 764px) {
  .container-event {
    padding: unset;
  }
}

/* About */
.container-about {
  background: #1E1E1E;
  color: white;
}

.container-about-talk {
  background: #000;
  color: white;
}

.section-title-about h2 {
  color: #fff;
  font-size: 1.25rem;
  text-align: left;
  font-weight: 600;
}

.section-title-about-people h2 {
  color: #fff;
  font-size: 1.25rem;
  text-align: center;
  font-weight: 600;
}

.justify-content-center-about {
  text-align: center;
}


.nav-demo-list .nav-item a {
  background: transparent;
  color: #fff !important;
}

.nav-demo-list .nav-item .active {
  /* color: #f1950a !important; */
  background: transparent !important;
  font-weight: 600;
  border-bottom: 2px solid #f1950a;
  border-radius: 0;
}

.contact-form-input {
  background: rgba(255, 255, 255, 0.05) !important;
  border: 1px solid rgba(0, 0, 0, 0.422) !important;
  color: #fff !important;
  border-radius: 0 !important;
}

::placeholder {
  color: white !important;
  opacity: 1;
  font-size: '0.8em' !important;
  opacity: 0.2 !important;
  /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  font-size: '0.8em' !important;
  color: white !important;
  opacity: 0.2 !important;
}

.swiper-pagination {
  text-align: left !important;
}

.swiper-pagination-bullets-dynamic {
  transform: unset !important;
}

.swiper-pagination-bullets {
  top: 10% !important;
  left: 0% !important;

}

.swiper-pagination-bullets .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  background: #fff;
}

.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 26px;
  height: 15px;
  background: #fff;
  border-radius: 10px !important;
}

/* Dark Theme for SweetAlert */
.swal2-popup {
  background-color: #1e1d1d !important;
  /* Background color for the alert container */
}

.swal2-title {
  color: rgb(241, 149, 9) !important;
}

.swal2-confirm {
  background: rgb(241, 149, 9) !important;
}

.swal2-html-container {
  color: #fff !important;
}

.swal2-success-ring {
  border-color: rgb(241, 149, 9) !important;
}

.swal2-success-line-long {
  background-color: rgb(241, 149, 9) !important;
}

.swal2-success-line-tip {
  background-color: rgb(241, 149, 9) !important;
}